import { Col, FormProps } from 'antd'
import { plusSVG } from 'assets/svg/plus'
import PrimaryButton from 'components/button/PrimaryButton'
import SelfServeBreakerInfoCard from 'components/card/SelfServeBreakerInfoCard'
import SelfServeInfoCard from 'components/card/SelfServeInfoCard'
import AddBreakerModal from 'feature/modal/projects/AddBreakerModal'
import AddPanelModal from 'feature/modal/projects/AddPanelModal'
import usePanelListForm from 'hooks/projects/usePanelListForm'
import { SelfServePanelSchema } from 'models/self-serve-commissioning'
import { theme } from 'theme'

export interface PanelListFormProps extends FormProps {
  initialValues?: SelfServePanelSchema[]
  updateInstallation: (request: SelfServePanelSchema[], callBack?: () => void) => Promise<void>
}

const PanelListForm: React.FC<PanelListFormProps> = ({ initialValues, updateInstallation }) => {
  const {
    openAddPanel,
    openAddBreaker,
    selectedPanel,
    selectedBreakerIndex,
    handleAddPanel,
    handleAddBreaker,
    updatePanel,
    updateBreaker,
    panelModifyOptions,
    breakerModifyOptions,
  } = usePanelListForm({ initialValues, updateInstallation })

  return (
    <>
      <AddPanelModal
        updateInstallation={updatePanel}
        isModalOpen={openAddPanel}
        handleModalOpen={handleAddPanel(undefined)}
        initialValues={selectedPanel}
      />
      <AddBreakerModal
        initialValues={selectedPanel?.breakers?.[selectedBreakerIndex]}
        updateInstallation={updateBreaker}
        isModalOpen={openAddBreaker}
        handleModalOpen={handleAddBreaker(undefined, -1)}
      />
      <Col>
        <p className="heading-03-bold">{'Panel and Breaker'}</p>
        <p className="heading-06-regular">
          {
            'Enter details about the panel supplying power to the chargers, including its rating, feeder loads, and identifying labels.'
          }
        </p>
        <p className="heading-06-regular">
          {
            'Then, specify the breaker slot pairings for the EV chargers connected to the panel. If chargers are connected to multiple panels, add each additional panel along with its associated EV charging breakers.'
          }
        </p>
        <p className="heading-06-regular">
          {'In the next step, you can link the chargers to their respective panels and breakers.'}
        </p>
        {initialValues?.map((panel) => (
          <SelfServeInfoCard
            image={panel?.photos?.[0]}
            title={panel?.label}
            subtitles={[`${panel?.type} Panel · ${panel?.ratedCapacity}A`, panel?.loc || '']}
            options={panelModifyOptions(panel)}
            body={{
              title: 'Breaker',
              buttonText: 'Add a Breaker',
              onClick: handleAddBreaker(panel, -1),
              buttonActive: !panel?.breakers?.length,
            }}
          >
            <Col>
              {panel?.breakers?.map((breaker, index) => (
                <SelfServeBreakerInfoCard
                  title={`${breaker.breakerNumber} · ${breaker.ratedCapacity}Amp`}
                  options={breakerModifyOptions(panel, index)}
                />
              ))}
            </Col>
          </SelfServeInfoCard>
        ))}
        <PrimaryButton
          style={{
            marginTop: 15,
            ...(!!initialValues?.length
              ? { backgroundColor: theme.colors.white, border: `1px solid ${theme.colors.grayScale}` }
              : {}),
          }}
          onClick={handleAddPanel(undefined)}
          size="large"
          title={`Add a panel`}
          icon={plusSVG}
        />
      </Col>
    </>
  )
}

export default PanelListForm
